import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Meta } from "@angular/platform-browser";
import { FadeInAnimation } from "app/shared/animations/fadein.animation";

import * as fromAuth from "../reducers";
import { ForgotPassword } from "../models/forgot-password.model";
import { AuthActions } from "../actions/auth.actions";

@Component({
	selector: "forgot-password-page",
	template: `
		<forgot-password-input
			(forgotPassword)="forgotPassword($event)"
			[pending]="pending$ | async"
			[errorMessage]="error$ | async"
			[send_success]="send_success$ | async"
		>
		</forgot-password-input>
	`,
	host: {
		"[class.dark-theme]": "dark",
		"[@FadeInAnimation]": "",
	},
	animations: [FadeInAnimation],
	styles: [
		`
			:host {
				display: block;
				height: 100%;
			}
		`,
	],
})
export class ForgotPasswordPageComponent {
	dark = true;
	pending$ = this._store.select(fromAuth.getForgotPasswordPagePending);
	error$ = this._store.select(fromAuth.getForgotPasswordPageError);
	send_success$ = this._store.select(fromAuth.getForgotPasswordPageSuccess);

	constructor(
		private readonly _metaService: Meta,
		private readonly _store: Store<fromAuth.State>,
	) {
		this._metaService.addTag({ name: "robots", content: "noindex" });
		// this._store.dispatch(new authActions.SigninErrorClearAction());
	}

	forgotPassword(email: ForgotPassword) {
		this._store.dispatch(AuthActions.forgotPassword({ payload: email }));
	}
}
