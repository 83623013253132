import { Component, EventEmitter, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";
import { FadeInAnimation } from "app/shared/animations/fadein.animation";
import {
	trigger,
	state,
	animate,
	transition,
	style,
} from "@angular/animations";
import { Store } from "@ngrx/store";

import * as fromAuth from "../reducers";
import { UserRegister } from "../models/user-register.model";
import { AuthActions } from "../actions/auth.actions";

@Component({
	selector: "register-page",
	template: `
		<div class="register-wrapper">
			<div class="register-container">
				<register-input
					(register)="register($event)"
					[registrationKey]="registrationKey"
					[pending]="pending$ | async"
					[error]="error$ | async"
					[verify_pending]="verify_pending$ | async"
					[verify_error]="verify_error$ | async"
					[limited_user]="limited_user$ | async"
				>
				</register-input>
			</div>
		</div>
	`,
	host: {
		"[class.dark-theme]": "dark",
		"[@FadeInAnimation]": "",
	},
	animations: [FadeInAnimation],
	styles: [
		`
			:host {
				display: block;
				height: 100%;
			}
		`,
	],
})
export class RegisterPageComponent implements OnDestroy {
	dark = true;
	pending$ = this._store.select(fromAuth.getRegisterPagePending);
	error$ = this._store.select(fromAuth.getRegisterPageError);
	verify_pending$ = this._store.select(fromAuth.getRegisterPageVerifyPending);
	verify_error$ = this._store.select(fromAuth.getRegisterPageVerifyError);
	limited_user$ = this._store.select(fromAuth.getRegisterPageLimitedUser);

	registrationKey: string;

	private readonly keySubscription: Subscription;

	constructor(
		private readonly _store: Store<fromAuth.State>,
		private readonly _route: ActivatedRoute,
	) {
		this._store.dispatch(AuthActions.registerErrorClear());
		this.keySubscription = this._route.queryParams.subscribe((params) => {
			if (params.regKey) {
				this.registrationKey = params.regKey;
				this._store.dispatch(
					AuthActions.verifyRegisterToken({ token: this.registrationKey }),
				);
			}
		});
	}

	register(userRegister: UserRegister) {
		// console.log("Dispatching register: ", userRegister);
		this._store.dispatch(AuthActions.register({ payload: userRegister }));
	}

	ngOnDestroy() {
		if (this.keySubscription) {
			this.keySubscription.unsubscribe();
		}
	}
}
