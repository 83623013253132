import { NgModule } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { TranslocoModule } from "@jsverse/transloco";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

import { SigninPageComponent } from "./pages/signin.component";
import { RegisterPageComponent } from "./pages/register.component";
import { ForgotPasswordPageComponent } from "./pages/forgot-password.component";
import { ResetPageComponent } from "./pages/reset.component";
import {
	ForgotPasswordInputComponent,
	PasswordInputComponent,
	PasswordResetInputComponent,
	RegisterInputComponent,
	SigninInputComponent,
	SsoSectionComponent,
} from "./components";
import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./services/auth-guard.service";
import { AuthEffects } from "./effects/auth.effects";
import { reducers } from "./reducers";
import { ApiService } from "./services/api.service";
import { TranslateErrorService } from "../../shared/errors/handleError";

export const COMPONENTS = [
	SigninPageComponent,
	SigninInputComponent,
	RegisterPageComponent,
	RegisterInputComponent,
	ForgotPasswordPageComponent,
	ResetPageComponent,
	ForgotPasswordInputComponent,
	PasswordResetInputComponent,
	PasswordInputComponent,
	SsoSectionComponent,
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild([
			{ path: "signin", component: SigninPageComponent },
			{ path: "register", component: RegisterPageComponent },
			{ path: "reset", component: ResetPageComponent },
			{ path: "forgot-password", component: ForgotPasswordPageComponent },
		]),
		CommonModule,
		HttpClientModule,
		ReactiveFormsModule,
		StoreModule.forFeature("auth", reducers),
		EffectsModule.forFeature([AuthEffects]),
		TranslocoModule,
	],
	declarations: COMPONENTS,
	exports: COMPONENTS,
	providers: [
		AuthService,
		AuthGuard,
		ApiService,
		CookieService,
		Meta,
		TranslateErrorService,
	],
})
export class AuthModule {}
