// import the required animation functions from the angular animations module
import {
	trigger,
	state,
	animate,
	transition,
	style,
} from "@angular/animations";

export const FadeInAnimation = trigger("FadeInAnimation", [
	transition("void => *", [
		style({ opacity: 0 }),
		animate("0.3s ease-in-out", style({ opacity: 1 })),
	]),
]);
