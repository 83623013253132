import { Component, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { FadeInAnimation } from "app/shared/animations/fadein.animation";
import { EnvService } from "app/shared/services/env.service";

import * as fromAuth from "../reducers";
import { UserSignin } from "../models/user-signin.model";
import { AuthActions } from "../actions/auth.actions";
import { AuthService } from "../services/auth.service";

@Component({
	selector: "signin-page",
	template: `
		<div id="main" class="ui middle aligned center aligned grid">
			<div class="column">
				<es-signin-input
					(signin)="signin($event)"
					[pending]="pending$ | async"
					[errorMessage]="error$ | async"
				>
				</es-signin-input>

				<es-auth-sso-section
					*ngIf="showOAuthButtons"
					(initSignIn)="signInOAuth()"
				></es-auth-sso-section>
			</div>
		</div>
	`,
	host: {
		"[class.dark-theme]": "dark",
		"[@FadeInAnimation]": "",
	},
	animations: [FadeInAnimation],
	styles: [
		`
			:host {
				display: block;
				height: 100%;
			}

			#main {
				height: 100%;
			}
		`,
	],
})
export class SigninPageComponent implements OnInit {
	showOAuthButtons = false;

	dark = true;
	pending$ = this._store.select(fromAuth.getSigninPagePending);
	error$ = this._store.select(fromAuth.getSigninPageError);

	constructor(
		private readonly _store: Store<fromAuth.State>,
		private readonly _metaService: Meta,
		private readonly _env: EnvService,
		private readonly _authService: AuthService,
		private readonly _route: ActivatedRoute,
	) {
		this._metaService.addTag({ name: "robots", content: "noindex" });
		this._store.dispatch(AuthActions.signinErrorClear());
		this.showOAuthButtons = this._env.es_api_server.includes("tilray");
	}

	ngOnInit() {
		const redirectUrl = this._route.snapshot.queryParamMap.get("redirect");
		if (redirectUrl) {
			this._authService.redirectUrl = redirectUrl;
		}
	}

	signin(userSignin: UserSignin) {
		this._store.dispatch(AuthActions.signin({ signin: userSignin }));
	}

	signInOAuth() {
		const url = new URL(
			`${this._env.es_api_server}${this._env.es_client_api}/providers/microsoft`,
		);
		url.searchParams.set("token_redirect", window.location.origin);

		window.location.href = url.toString();
	}
}
