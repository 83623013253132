import { Injectable } from "@angular/core";
import {
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Route,
} from "@angular/router";

import { AuthService } from "./auth.service";

// Prevents access to dashboard routes when unauthenticated.
@Injectable()
export class AuthGuard {
	constructor(
		private readonly _router: Router,
		private readonly authService: AuthService,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// console.log("Can Activate Called");
		if (this.authService.authenticated) {
			return true;
		}
		// console.log("Navigating to signin screen with return url", state.url);
		// Store the attempted URL for redirecting
		this.authService.redirectUrl = state.url;

		this._router.navigate(["/signin"]);
		// this.router.navigate(['/signin'], { queryParams: { returnUrl: state.url }});
		return false;
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean {
		// console.log("Can Activate child called");
		return this.canActivate(route, state);
	}

	canLoad(route: Route): boolean {
		// console.log("Can Load called");
		const url = `/${route.path}`;
		return this.checkLogin(url);
	}

	checkLogin(url: string): boolean {
		if (this.authService.authenticated) {
			return true;
		}

		// Store the attempted URL for redirecting
		this.authService.redirectUrl = url;

		this._router.navigate(["/signin"]);

		return false;
	}
}
