import { tap, catchError, switchMap, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, EMPTY, of } from "rxjs";

import { AuthService } from "../services/auth.service";
import { Auth } from "../models/auth.model";
import { LimitedUser } from "../models/limited-user.model";
import { AuthActions } from "../actions/auth.actions";

@Injectable()
export class AuthEffects {
	register$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.register),
			map((action) => action.payload),
			switchMap((payload) => {
				if (!payload) {
					return EMPTY;
				}
				return this.authService.register(payload).pipe(
					map((result) => {
						return AuthActions.registerSuccess({ payload: result });
					}),
					catchError((error) => {
						return of(AuthActions.registerFail({ error }));
					}),
				);
			}),
		),
	);

	registerSuccess$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.registerSuccess),
			switchMap((_) => this.router.navigate(["/dashboard/locations"])),
			switchMap((success) => of(AuthActions.navigationSuccess({ success }))),
		),
	);

	signin$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.signin),
			map((action) => action.signin),
			switchMap((payload) => {
				if (!payload) {
					return EMPTY;
				}
				return this.authService.signin(payload).pipe(
					map((result: Auth) => {
						return AuthActions.signinSuccess({ auth: result });
					}),
					catchError((error) => {
						return of(AuthActions.signinFail({ error }));
					}),
				);
			}),
		),
	);

	signinSuccess$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.signinSuccess),
			switchMap((_) => {
				if (this.authService.redirectUrl) {
					const [url, query] = this.authService.redirectUrl.split("?");
					// Reduce params into object to be used in queryParams for angular navigate. Otherwise the '?' will get encoded and the returned url will be incorrect.
					const params = new HttpParams({ fromString: query });
					const paramsObj = params.keys().reduce((acc, key) => {
						acc[key] = params.get(key);
						return acc;
					}, {});
					// login successful so redirect to return url
					return this.router.navigate([url], { queryParams: paramsObj });
				} else {
					return this.router.navigate(["/dashboard/locations"]);
				}
			}),
			switchMap((success) => of(AuthActions.navigationSuccess({ success }))),
		),
	);

	logout$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.logout),
			switchMap(() => {
				return this.authService.logout().pipe(
					map((success) => {
						if (success) {
							return AuthActions.logoutRedirect();
						} else {
							return AuthActions.logoutFail({ error: "Unknown Error" });
						}
					}),
					catchError((error) => {
						return of(AuthActions.logoutFail({ error }));
					}),
				);
			}),
		),
	);

	logoutRedirect: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.logoutRedirect),
			switchMap((_) => this.router.navigate(["/signin"])),
			switchMap(() => of(AuthActions.logoutSuccess())),
		),
	);

	logoutSuccess: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.logoutSuccess),
			switchMap((_) => of(AuthActions.navigationSuccess({ success: true }))),
		),
	);

	authenticate$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.authenticate),
			switchMap(() => {
				return this.authService.authenticate().pipe(
					map((result) => {
						return AuthActions.authenticateSuccess({ auth: result });
					}),
					catchError((error) => of(AuthActions.authenticateFail({ error }))),
				);
			}),
		),
	);

	authFailed$: Observable<Action> = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.authenticateFail),
				tap((action) => {
					this.router.navigate(["/signin"]);
				}),
			),
		{ dispatch: false },
	);

	verifyRegisterToken$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.verifyRegisterToken),
			map((action) => action.token),
			switchMap((payload) => {
				if (!payload) {
					return EMPTY;
				}
				return this.authService.validateRegistrationToken(payload).pipe(
					map((result: LimitedUser) => result),
					map((result) => {
						return AuthActions.verifyRegisterTokenSuccess({ user: result });
					}),
					catchError((error) => {
						return of(AuthActions.verifyRegisterTokenFail({ error }));
					}),
				);
			}),
		),
	);

	forgotPassword$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.forgotPassword),
			map((action) => action.payload),
			switchMap((payload) => {
				if (!payload) {
					return EMPTY;
				}
				return this.authService.forgotPassword(payload).pipe(
					map((result) => {
						return AuthActions.forgotPasswordSuccess();
					}),
					catchError((error) => {
						return of(AuthActions.forgotPasswordFail({ error }));
					}),
				);
			}),
		),
	);

	passwordReset$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.resetPassword),
			map((action) => action.payload),
			switchMap((payload) => {
				if (!payload) {
					return EMPTY;
				}
				return this.authService.passwordReset(payload).pipe(
					map(() => {
						return AuthActions.resetPasswordSuccess();
					}),
					catchError((error) => {
						return of(AuthActions.resetPasswordFail({ error }));
					}),
				);
			}),
		),
	);

	verifyResetToken$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.verifyResetToken),
			map((action) => action.token),
			switchMap((payload) => {
				if (!payload) {
					return EMPTY;
				}
				return this.authService.validateResetToken(payload).pipe(
					map((result: boolean) => result),
					map((result) => {
						return AuthActions.verifyResetTokenSuccess();
					}),
					catchError((error) => {
						return of(AuthActions.verifyResetTokenFail({ error }));
					}),
				);
			}),
		),
	);

	constructor(
		private readonly actions$: Actions,
		private readonly authService: AuthService,
		private readonly router: Router,
	) {}
}
