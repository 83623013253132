import { Component, OnDestroy } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { FadeInAnimation } from "app/shared/animations/fadein.animation";

import * as fromAuth from "../reducers";
import { PasswordReset } from "../models/password-reset.model";
import { AuthActions } from "../actions/auth.actions";

@Component({
	selector: "reset-password-page",
	// providers: [BatchesService, LocationsService],
	template: `
		<password-reset-input
			(resetPassword)="resetPassword($event)"
			[reset_key]="reset_key"
			[pending]="pending$ | async"
			[error]="error$ | async"
			[verify_pending]="verify_pending$ | async"
			[verify_error]="verify_error$ | async"
			[verify_success]="verify_success$ | async"
			[reset_success]="reset_success$ | async"
		>
		</password-reset-input>
	`,
	host: {
		"[class.dark-theme]": "dark",
		"[@FadeInAnimation]": "",
	},
	animations: [FadeInAnimation],
	styles: [
		`
			:host {
				display: block;
				height: 100%;
			}
		`,
	],
})
export class ResetPageComponent implements OnDestroy {
	dark = true;
	pending$ = this._store.select(fromAuth.getPasswordResetPagePending);
	error$ = this._store.select(fromAuth.getPasswordResetPageError);
	reset_success$ = this._store.select(fromAuth.getPasswordResetSuccess);
	verify_success$ = this._store.select(fromAuth.getResetTokenVerifySuccess);
	verify_pending$ = this._store.select(fromAuth.getPasswordResetVerifyPending);
	verify_error$ = this._store.select(fromAuth.getPasswordResetVerifyError);

	reset_key: string;

	private readonly keySubscription: Subscription;

	constructor(
		private readonly _route: ActivatedRoute,
		private readonly _metaService: Meta,
		private readonly _store: Store<fromAuth.State>,
	) {
		this._metaService.addTag({ name: "robots", content: "noindex" });
		this.keySubscription = this._route.queryParams.subscribe((params) => {
			if (params.rk) {
				this.reset_key = params.rk;
				this._store.dispatch(
					AuthActions.verifyResetToken({ token: this.reset_key }),
				);
			}
		});
	}

	ngOnDestroy() {
		if (this.keySubscription) {
			this.keySubscription.unsubscribe();
		}
	}

	resetPassword(reset: PasswordReset) {
		this._store.dispatch(AuthActions.resetPassword({ payload: reset }));
	}
}
